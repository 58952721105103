// configUtils.js
import { useNavigate } from "react-router-dom";
import { fetchRobotConfig } from "./api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const handlePullConfig = async (id, setConfigLoaded, setSliderValue, setSelectedOptions, setEasyOptions, setMediumOptions, setHardOptions, setDirectionConfig, setNumBalls, setFrequencyValue, setSubmissionData, setStrokes, setDrillConfig, setDirectionValue, setMiddleValue, navigate) => {
  const idRegex = /^[0-9A-Fa-f]{2}(:[0-9A-Fa-f]{2}){5}$/;
  console.log(id);
  if(!id){
    navigate(-1);
  }
  if (!idRegex.test(id)) {
    toast.error('ID must match the format "FC:B4:67:92:2F:7E"');
    return;
  }

  setConfigLoaded(false);
  setSliderValue([0]);
  setSelectedOptions([[]]);
  setEasyOptions([]);
  setMediumOptions([]);
  setHardOptions([]);
  setDirectionConfig([]);
  setNumBalls('');
  setFrequencyValue(1);
  setSubmissionData(null);

  try {
    const data = await fetchRobotConfig(id);
    console.log('Fetched data:', data);
    setStrokes(data);
    setDrillConfig(data);
    data.forEach(item => {
      const { level, stroke, config } = item;
      if (level === 'servo_H' && stroke === 'servo_H') {
        setDirectionConfig(config);
      }
      setDirectionValue([[config.backhand, config.forehand]]);
      setMiddleValue(config.middle)
      switch (level) {
        case 'beginner':
          setEasyOptions(prevOptions => {
            const uniqueOptions = Array.from(new Set([...prevOptions, stroke]));
            return uniqueOptions.sort();
          });
          break;
        case 'intermediate':
          setMediumOptions(prevOptions => {
            const uniqueOptions = Array.from(new Set([...prevOptions, stroke]));
            return uniqueOptions.sort();
          });
          break;
        case 'advanced':
          setHardOptions(prevOptions => {
            const uniqueOptions = Array.from(new Set([...prevOptions, stroke]));
            return uniqueOptions.sort();
          });
          break;
        case 'servo_H':
          break;
        default:
          console.error('Invalid difficulty level:', level);
      }
    });
    setConfigLoaded(true);
  } catch (error) {
    console.error('Error:', error);
  }
};

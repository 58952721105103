import axios from 'axios';
import { PONGFOX_FIX_URL, BACKEND_API_URL_CREATE_DRILL } from "../constants/constants";

export const fetchRobotConfig = async (id) => {
  const response = await axios.post(`${PONGFOX_FIX_URL}/config/robot`, {
    id: id
  });
  
  if (!response.data) {
    throw new Error('Invalid response data');
  }

  return response.data;
};

export const submitToApi = async (data) => {
  try {
    console.log(data);
      const response = await axios.post(`${BACKEND_API_URL_CREATE_DRILL}`, data)

    // const response = await axios.post(`${BACKEND_API_URL_CREATE_DRILL}`, data)
    .then((response) => {
      console.log('Success:', response.data);
    })
    .catch((error) => {
      console.error(error);
    })

    console.log(response);
  } catch (error) {
    console.error('Error:', error);
  }
}
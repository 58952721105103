import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Container, Grid, Paper } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PONGFOX_FIX_URL, BACKEND_API_URL } from "../constants/constants";

// const BACKEND_API_URL = "https://app.pongfox.com/ai";
// const BACKEND_API_URL = "http://192.168.0.101:8000";

function DeviceIdCheck() {
  const [deviceId, setDeviceId] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const isValidDeviceId = (id) => {
    const macAddressRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
    const vPFormatRegex = /^[vV][0-9]+[pP]?$|^[vV][0-9]+$/;
    return macAddressRegex.test(id) || vPFormatRegex.test(id);
  };

  const isVPFormat = (id) => {
    return /^[vV][0-9]+[pP]?$|^[vV][0-9]+$/.test(id);
  };


  const fetchAndroidProperty = async (id) => {
    try { 
     const response = await fetch(`${PONGFOX_FIX_URL}/config/ble?id=${id}`);
      // const response = await fetch(`http://192.168.0.101:8000/config/ble?id=${id}`);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.android) {
        navigate(`/device?device_id=${data.android}`);
      } else {
        toast.error('Invalid Device ID');
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
    }
  };

  const checkPassword = async (deviceId, password) => {
    try {
      const response = await fetch(`${BACKEND_API_URL}/device/check_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ device_id: deviceId, password: password }),
      });

      const data = await response.json();

      if (data === true) {
        navigate(`/device?device_id=${deviceId}`, { state: { DefaultDevice: true } });
      } else {
        toast.error('Incorrect password. Please try again.');
      }
    } catch (error) {
      toast.error(`Error checking password: ${error.message}`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValidDeviceId(deviceId)) {
      if ((!passwordVisible) && deviceId === 'AB:CD:12:34:56:78') {
        setPasswordVisible(true);
      }else if (isVPFormat(deviceId)) {
        fetchAndroidProperty(deviceId);
      } else {
        if(passwordVisible){
          if (password) {
            checkPassword(deviceId, password);
          } else {
            toast.error('Please enter your password.');
          }
        }
        else{
          navigate(`/device?device_id=${deviceId}`);
        }
      }
    } else {
      toast.error('Invalid Device ID format. Please use CC:7B:5C:34:D0:56 format or v00 or v00p format.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
  <header className="App-header">
    <Container maxWidth="md" className="appContainer">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={12} sm={8} md={6}>
          <Paper elevation={3} style={{ padding: '20px', backgroundColor: 'lightblue' }}>
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="deviceId"
                label="Device ID"
                name="deviceId"
                autoFocus
                value={deviceId}
                style={{ backgroundColor: 'whitesmoke' }}
                onChange={(e) => setDeviceId(e.target.value)}
              />
              {passwordVisible && (
                <Grid item xs={12}>
                  <TextField
                    label="Password"
                    type="password"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <ToastContainer />
    </Container>
  </header>
  );
}

export default DeviceIdCheck;
import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import moment from 'moment';
import axios from 'axios';
import { BACKEND_API_URL } from "../constants/constants";


const HeatMap = () => {
    const [data, setData] = useState([]);
    const [lastDoc, setLastDoc] = useState(null);
    const [loading, setLoading] = useState(false);
    const [heatmapData, setHeatmapData] = useState([]);
    const [deviceId, setDeviceId] = useState(null);
    const location = useLocation();

    // const fetchMoreData = async () => {
    //     if (loading) return;
    //     setLoading(true);
    //     try {
    //         let q = query(collection(db, "dynamic_drill"), where("ble", "==", "D4:36:39:C6:0C:34"));
    //         if (lastDoc) {
    //             q = query(q, startAfter(lastDoc));
    //         }
    //         // const querySnapshot = await getDocs(q);
    //         // const newDocs = querySnapshot.docs.map(doc => ({
    //         //     id: doc.id,
    //         //     ...doc.data()
    //         // }));
    //         const querySnapshot = await getDocs(q);
    //         const drillData = querySnapshot.docs.map(doc => ({
    //             date: moment.unix(doc.data().timestamp.seconds).format('YYYY-MM-DD'),
    //             count: 1
    //         }));

    //         // Aggregate data by date
    //         const aggregatedData = drillData.reduce((acc, curr) => {
    //             const existingDate = acc.find(item => item.date === curr.date);
    //             if (existingDate) {
    //                 existingDate.count += curr.count;
    //             } else {
    //                 acc.push(curr);
    //             }
    //             return acc;
    //         }, []);

    //         setHeatmapData(aggregatedData);
    //         // setData(prevData => [...prevData, ...newDocs]);
    //         // setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
    //     } catch (error) {
    //         console.error("Error fetching data:", error)
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const deviceIdParam = searchParams.get('device_id');
        setDeviceId(deviceIdParam);
        fetchData(deviceId);
    }, [deviceId]);

    const fetchData = async (ble_id) => {
        setLoading(true);
        try {
            const response = await axios.get(`${BACKEND_API_URL}/heatmap-data/${ble_id}`);
            setHeatmapData(response.data);
        } catch (err) {
            console.error("Error fetching data:", err);
        } finally {
            setLoading(false);
        }
    };

    function convertSeconds(seconds) {
        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds % 3600) / 60);
        let secs = Math.ceil(seconds % 60);

        let result = [];
        if (hours > 0) {
            result.push(`${hours} hr`);
        }
        if (minutes > 0) {
            result.push(`${minutes} min`);
        }
        if (secs > 0 || (hours === 0 && minutes === 0)) {
            result.push(`${secs} sec`);
        }

        return result.join(' ');
    }


    const getColor = (count) => {
        if (!count) return 'color-empty';
        if (count < 2) return 'color-scale-1';
        if (count < 30) return 'color-scale-2';
        if (count < 50) return 'color-scale-3';
        return 'color-scale-4';
    };

    return (
        <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
            <h2>Drill Activity Heatmap</h2>
            {loading ? (
                <div className="loading-spinner"></div>
            ) : (
                <>
                    <CalendarHeatmap
                        startDate={moment().subtract(3, 'months').startOf('month')}
                        endDate={moment().endOf('month')}
                        values={heatmapData}
                        showMonthLabels={true}
                        monthLabels={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']}
                        classForValue={(value) => getColor(value ? value.total : 0)}
                        titleForValue={(value) => {
                            if (!value) {
                                return 'No drills';
                            }
                            return `${value.total} drills played for ${convertSeconds(value.total_seconds)}`;
                        }}
                        tooltipDataAttrs={value => {
                            return {
                                'data-tip': `${value.total} drills played for ${value.total_seconds} seconds on ${value.date}`,
                            };
                        }}
                    />
                    <style>{`
                .calendar-heatmap-container {
                    overflow-x: auto;
                }
                .react-calendar-heatmap {
                    font-size: 8px;
                }
                .react-calendar-heatmap .react-calendar-heatmap-small-text {
                    font-size: 5px;
                }
                .react-calendar-heatmap rect {
                    rx: 2;
                    ry: 2;
                }
                .react-calendar-heatmap .color-empty { fill: #ebedf0; }
                .react-calendar-heatmap .color-scale-1 { fill: #c6e48b; }
                .react-calendar-heatmap .color-scale-2 { fill: #7bc96f; }
                .react-calendar-heatmap .color-scale-3 { fill: #239a3b; }
                .react-calendar-heatmap .color-scale-4 { fill: #196127; }
            `}</style>
                </>
            )}

        </div>
    );
};

export default HeatMap;


const PONGFOX_FIX_URL = "https://app.pongfox.com";

const PONGFOX_FIX_URL_DRILL_V1 = "https://app.pongfox.com/drill/v1";

const PONGFOX_FIX_URL_DRILL_SEMIRANDOM = "https://app.pongfox.com/drill/semirandom";


//const BACKEND_API_URL = "http://192.168.0.233:8000"; // This use for localhost

const BACKEND_API_URL = "https://app.pongfox.com/ai";

const BACKEND_API_URL_MYDRILLS_DEVICE = `${BACKEND_API_URL}/mydrills/device`;

const BACKEND_API_URL_CREATE_DRILL = `${BACKEND_API_URL}/create_drill`;


export { PONGFOX_FIX_URL, PONGFOX_FIX_URL_DRILL_V1, PONGFOX_FIX_URL_DRILL_SEMIRANDOM, BACKEND_API_URL, BACKEND_API_URL_MYDRILLS_DEVICE, BACKEND_API_URL_CREATE_DRILL };

import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const SelectedOptions = ({ selectedOptions, handleRemoveOption }) => {
  return (
    <Box sx={{ mt: 2 }}>
      {selectedOptions.map((option, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1, color:'#1976d2' }}>
          <Typography sx={{ mr: 2 }}>{option.label} ({option.difficulty})</Typography>
          <Button variant="outlined" onClick={() => handleRemoveOption(option.label)}>
            Remove
          </Button>
        </Box>
      ))}
    </Box>
  );
};

export default SelectedOptions;

import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PONGFOX_FIX_URL, PONGFOX_FIX_URL_DRILL_V1, PONGFOX_FIX_URL_DRILL_SEMIRANDOM, BACKEND_API_URL, BACKEND_API_URL_MYDRILLS_DEVICE } from "../constants/constants";

// const BACKEND_API_URL = "http://192.168.0.101:8000";
// const BACKEND_API_URL = "https://app.pongfox.com/ai";

function CreateSession() {
  
  const [deviceId, setDeviceId] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const passwordVerified = location.state?.DefaultDevice || false;

  const [showPopup, setShowPopup] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [drills, setDrills] = useState([]);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [apiEndpoint, setApiEndpoint] = useState(`${PONGFOX_FIX_URL_DRILL_V1}`);
  //const [apiEndpoint, setApiEndpoint] = useState('http://192.168.0.101:8000/drill/v1');
  const [apiType, setApiType] = useState('v1');
  const [sessionName, setSessionName] = useState('');
  const [sessionId, setSessionId] = useState(null); // Add this state for storing session id
  const [sessionDesc, setSessionDesc] = useState('');
  const [hasId, setHasId] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeApi, setActiveApi] = useState('v1');
  const [isUpdateMode, setIsUpdateMode] = useState(false);


  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [sessionToDelete, setSessionToDelete] = useState(null);

  const [sessionsLoading, setSessionsLoading] = useState(true);


  const isValidDeviceId = (id) => {
    const macAddressRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
    const vPFormatRegex = /^[vV][0-9]+[pP]?$|^[vV][0-9]+$/;
    return macAddressRegex.test(id) || vPFormatRegex.test(id);
  };

  const isVPFormat = (id) => {
    return /^[vV][0-9]+[pP]?$|^[vV][0-9]+$/.test(id);
  };

  const fetchAndroidProperty = async (id) => {
    try {
     // const response = await fetch(`${PONGFOX_FIX_URL}/config/ble?id=${id}`);
      const response = await fetch(`${PONGFOX_FIX_URL}/config/ble?id=${id}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.android) {
        navigate(`/device?device_id=${data.android}`);
      } else {
        toast.error('Invalid device ID');
        navigate('/enter-device-id');
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
    }
  };


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const deviceIdParam = searchParams.get('device_id');
    setDeviceId(deviceIdParam);

    if (deviceId) {
      setHasId(true);
      if(deviceId == "AB:CD:12:34:56:78" && !passwordVerified){
        toast.error("Enter password to access default device");
        navigate('/enter-device-id');
      }
      getSessions();
    }
    if (isValidDeviceId(deviceId)) {
      if (isVPFormat(deviceId)) {
        fetchAndroidProperty(deviceId);
      }
    }

    fetchData();

    setLoading(false);

  }, [location, apiEndpoint, deviceId]);

  useEffect(() => {
    if (!loading && !deviceId) {
      navigate('/enter-device-id');
    }
    if (!loading && !isValidDeviceId(deviceId)) {
      navigate('/enter-device-id');
    }

    if (location.state && location.state.selectedItems) {
      setSelectedItems(location.state.selectedItems);
    }

  }, [loading, deviceId])

  const getSessions = async () => {
    try {
      setSessionsLoading(true);
      const response = await fetch(`${BACKEND_API_URL}/sessions/device/${deviceId}`);

      if (!response.ok) {
        throw new Error('Failed to fetch sessions');
      }
      const data = await response.json();
      console.log(data);
      setSessions(data);
    } catch (error) {
      console.error('Error fetching sessions:', error);
      toast.error('Failed to load sessions. Please refresh the page.');
    } finally {
      setSessionsLoading(false);
    }
  };


  const fetchData = async () => {
    try {
      // await getSessions();
      if (apiType == "custom_drills") {
        const response = await axios.get(apiEndpoint);
        const formattedDrills = response.data.map(drill => {
          const configData = JSON.parse(drill.robo_json).random;
          const frequency = JSON.parse(drill.robo_json).frequency;
          const randomDataLength = Object.keys(configData).length;
          return {
            title: drill.title,
            apiType: apiType,
            drill_id: drill.id,
            // id: Math.floor(Math.random() * 10),
            drillDescription: drill.description,
            isDirect: drill.isDirect,
            config: drill.robo_json,
            variations: randomDataLength - 1,
            feedrate: frequency,
          };
        });
        setDrills(formattedDrills);
        console.log(formattedDrills);
      }
      else {
        const response = await axios.get(apiEndpoint);
        const formattedDrills = response.data.map(drill => {
          return {
            title: drill.title,
            apiType: apiType,
            // id: drill.id || drill.title,
            drillDescription: drill.desc,
            player_level: drill.player_level,
            variations: drill.variations || null,
            videoId: drill.videoId,
            config: JSON.stringify(drill.config),
            random: JSON.stringify(drill.random) || "",
            randomConfig: JSON.stringify(drill.randomConfig) || "",
            tags: drill.tags,
            mirror: drill.mirror,
            isMixed: drill.isMixed || 0,
            pause: drill.pause || 0
          };
        });
        setDrills(formattedDrills);
        console.log(formattedDrills);
        console.log(selectedItems);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleApiChange = (newEndpoint, newType) => {
    setApiEndpoint(newEndpoint);
    setApiType(newType);
    setActiveApi(newType);
  };


  const togglePopup = () => {
    setShowPopup(!showPopup);
    navigate(-1);
  };

  const handleCheckboxChange = (e) => {
    const selectedDrill = drills.find(drill => (drill.title === e.target.name));

    console.log(selectedDrill);
    let checkDrill = 1, checkRandom = 0;
    let drillType = "";
    if (apiType === "v1") {
      checkDrill = 1;
    }
    if (selectedDrill.isMixed === 1) drillType = "isMixed";
    else if (apiType === "semirandom") {
      checkRandom = 1;
    }
    if (checkRandom) drillType = "random";
    if (apiType === "custom_drills") {
      drillType = "isDirect";
    }

    const item = {
      title: e.target.name,
      apiType: apiType,
      numberOfBalls: 15,
      isDrill: checkDrill,
      feedrate: selectedDrill.feedrate || 1.0,
      repeat: 1,
      isRandom: checkRandom,
      drill_id: selectedDrill.drill_id,
      variations: selectedDrill.variations || 1,
      videoId: selectedDrill.videoId || "",
      config: selectedDrill.config,
      random: selectedDrill.random || "",
      randomConfig: selectedDrill.randomConfig || "",
      player_level: selectedDrill.player_level || "",
      level: selectedDrill.level || "intermediate",
      drillDescription: selectedDrill.drillDescription || "",
      tags: selectedDrill.tags || "",
      mirror: selectedDrill.mirror || "",
      drillType: drillType || "",
      pause: selectedDrill.pause || 0,
    };
    console.log(item);
    console.log("Selected items:", selectedItems);

    if (e.target.checked) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(selectedItems.filter(item => !(item.title === e.target.name)));
      if (e.target.name === "Falkenberg") {
        return !(item.title === e.target.name && item.drillDescription || selectedDrill.desc);
      } else {
        return item.title !== e.target.name;
      }
    }
  };


  const removeSelectedItem = (titleToRemove, idRemove) => {
    const itemSelected = selectedItems.filter(selectedItem => selectedItem.title === titleToRemove);
    setSelectedItems(selectedItems.filter(item => item !== itemSelected[0]));
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(checkbox => {
      if (checkbox.name === titleToRemove) {
        checkbox.checked = false;
      }
    });
  };

  const handleNumChange = (e, title, id) => {
    let num = parseInt(e.target.value);
    setSelectedItems(selectedItems.map(item => item.title === title && item.id === id ? { ...item, numberOfBalls: num } : item));
  }

  const handleInputNumBlur = (e, title, id) => {
    let value = parseInt(e.target.value);
    value = Math.min(Math.max(Number(value), 10), 75);
    setSelectedItems(selectedItems.map(item => item.title === title && item.id === id ? { ...item, numberOfBalls: value } : item));
  }

  const calculateTotalTime = () => {
    let totalballs = 0;
    selectedItems.map((item) => {
      totalballs += item.numberOfBalls;
    })
    let totalTimeSeconds = 1.0 * totalballs;
    const hours = Math.floor(totalTimeSeconds / 3600);
    const minutes = Math.floor((totalTimeSeconds % 3600) / 60);
    const seconds = totalTimeSeconds % 60;

    return { hours, minutes, seconds };
  };

  const submitSession = async () => {
    try {
      let totalballs = 0;
      selectedItems.map((item) => {
        totalballs += item.numberOfBalls;
      })
      let totalTime = 1.0 * totalballs;
      console.log(totalTime);

      const sessionData = {
        title: sessionName.trim(),
        id : sessionId,
        description: sessionDesc,
        duration: totalTime,
        device_id: deviceId,
        session_details: selectedItems.map((item, index) => ({
          title: item.title,
          apitype: item.apiType || item.apitype,
          isDrill: item.isDrill,
          drill_id:item.drill_id || null ,
          isRandom: item.isRandom,
          // id: parseInt(index + 1) || index + 1,
          srno: parseInt(index + 1) || index + 1,
          numberOfBalls: item.numberOfBalls,
          taskDuration: item.numberOfBalls * 1.0,
          variations: item.variations,
          feedrate: item.feedrate,
          repeat: item.repeat,
          level: item.level,
          drillType: item.drillType,
          drillDescription: item.drillDescription,
          tags: item.tags,
          mirror: item.mirror,
          player_level: item.player_level,
          videoId: item.videoId,
          config: item.config,
          random: item.random,
          randomConfig: item.randomConfig,
          pause: item.pause, 
          device_id: deviceId,
        })),
      };
      console.log('sessionData:', sessionData);
      const endpoint = isUpdateMode ? `${BACKEND_API_URL}/session/update/${deviceId}/${sessionId}` : `${BACKEND_API_URL}/custom/save`;


      const response = isUpdateMode ? await axios.put(endpoint, sessionData) : await axios.post(endpoint, sessionData);
      if (response && (response.status === 201 || response.status === 200 || response.status === 204)) {
        toast.success(isUpdateMode ? 'Session updated successfully!' : 'Session saved successfully!');
        getSessions();
      } else {
        toast.error('Failed to save session. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting session:', error);
      toast.error('Failed to save data!');
    }
  };


  const handleEnter = () => {
    if (!sessionName.trim()) {
      toast.error('Session name must be provided', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!sessionDesc.trim()) {
      toast.error('Please provide session description', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (selectedItems.length === 0) {
      toast.error('At least one drill item must be selected', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    console.log("Selected items:", selectedItems);
    setShowPopup(false);
    // setSessions([... , { name: sessionName, items: selectedItems }]);
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(checkbox => checkbox.checked = false);
    submitSession();
    setSelectedItems([]);
    setSessionName('');
    setSessionDesc('');
    setIsUpdateMode(false);
    console.log(sessions);
    setShowPopup(true);
    getSessions();
    // navigate(-1);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(selectedItems);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    setSelectedItems(reorderedItems);
  };

  const handleVariationChange = (e) => {
    const variation = parseInt(e.target.value);
    setSelectedVariation(variation);
  };

  const handleSessionClick = async (device_id, session_id, session_name ) => {
    console.log(session_id)
    const address = `${BACKEND_API_URL}/session/id/${device_id}/${session_id}`;
    console.log("Request URL:", address);

    
    const response = await fetch(address);
    const data = await response.json();
    console.log(data);
    setSelectedItems(data.session_details);
    setSessionId(data.id)
    setSessionName(data.title);
    setSessionDesc(data.description);
    setIsUpdateMode(true);
  }

  const handleSessionDelete = (device_id, session_id, session_name ) => {
    setSessionToDelete({ device_id, session_id, session_name });
    setShowDeletePopup(true);
  }

  const confirmDelete = async (device_id, session_id, session_name) => {
    try {
      const address = `${BACKEND_API_URL}/session/${device_id}/${session_id}`;
      
      const response = await axios.delete(address);

      if (response.status === 200) {
        toast.success('Session deleted successfully!');
        // Refresh the sessions list
        getSessions();
      } else {
        toast.error('Failed to delete session');
      }
    } catch (error) {
      console.error('Error deleting session:', error);
      toast.error('An error occurred while deleting the session');
    }

    setShowDeletePopup(false);
    setSessionToDelete(null);
    setSelectedItems([]);
    setSessionName('');
    setSessionDesc('');
    setIsUpdateMode(false);
  };

  const cancelDelete = () => {
    setShowDeletePopup(false);
    setSessionToDelete(null);
  };

  const handleNewSession = () => {
    setIsUpdateMode(false);
    setSelectedItems([]);
    setSessionName("");
    setSessionDesc("");
  }

  const durationDisplay = (
    <div className="text-display"><h4>
      Duration at feedrate 1.00s:
      {calculateTotalTime().hours > 0 &&
        `${calculateTotalTime().hours}h `
      }
      {calculateTotalTime().hours <= 0 &&
        `     `
      }
      {calculateTotalTime().minutes > 0 &&
        `${calculateTotalTime().minutes}m `
      }
      {calculateTotalTime().minutes <= 0 &&
        `     `
      }
      {calculateTotalTime().seconds}s
    </h4></div>
  );

  const sessionInputDisplay = (
    <div className="vertical-stack">
      <input
        disabled={isUpdateMode ? true : false}
        type="text"
        value={sessionName}
        onChange={(e) => setSessionName(e.target.value)}
        placeholder="Enter session name"
      />
      <input
        type="text"
        value={sessionDesc}
        onChange={(e) => setSessionDesc(e.target.value)}
        placeholder="Enter Description"
      />
    </div>
  );

  const savedSessions = (
    <div className="saved-sessions">
      {sessionsLoading ? (
        <p>Sessions Loading ...</p>
      ) : sessions.length > 0 ? (
        <>
          <h3 className="saved-sessions-heading">Saved Sessions</h3>
          {sessions.map((session, index) => {
            return (
            <div
              className="sessionItem"
              onClick={() => handleSessionClick(deviceId, session.id)}
              key={index}
            >
              <div className="sessionContent">
                <label className="sessionTitle">{session.title}</label>
                <label className="sessionDescription">{session.description}</label>
              </div>
              <button
                className="delete-button"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the click from propagating to the parent div
                  handleSessionDelete(deviceId, session.id, session.title);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                  <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                </svg>
              </button>
            </div>
          )})}
        </>
      ) : (
        <h4 className="saved-sessions-heading">No Saved Sessions Available.</h4>
      )}
    </div>
  );

  const block1Display = (
    <div>
      {savedSessions}
      <div className="section-separator"></div> {/* Separator line */}
      <h4 className="selectedDrills">Selected Drills:</h4>
      <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="selected-items">
            {(provided) => (
              <ul className="draggable-list" {...provided.droppableProps} ref={provided.innerRef}>
                {selectedItems.length > 0 ? (
                  selectedItems.map((item, index) => (
                    <Draggable key={`${item.title}${item.id}`} draggableId={item.title} index={index}>
                      {(provided) => (
                        <li
                          className="draggable-item"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className='itemTitle'>{item.title}</div>
                          <input
                            type="number"
                            value={item.numberOfBalls}
                            onBlur={(e) => handleInputNumBlur(e, item.title, item.id)}
                            onChange={(e) => handleNumChange(e, item.title, item.id)}
                          />
                          <button onClick={() => removeSelectedItem(item.title, item.id)}>X</button>
                        </li>
                      )}
                    </Draggable>
                  ))) : (
                  <>No drills selected!</>
                )}

                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
    </div>
  );

  const variationDisplay = (
    <div className='flex variation'>
      <label>Select Variation:</label>
      <select onChange={handleVariationChange} className='variationSelect'>
        <option value="">All</option>
        <option value="1">Variation 1</option>
        <option value="2">Variation 2</option>
        <option value="3">Variation 3</option>
        <option value="4">Variation 4</option>
        <option value="5">Variation 5</option>
      </select>
      {isUpdateMode ? <button  className="newSessionbtn" onClick={handleNewSession}>New Session</button>: ""}
    </div>
  );

  const buttonTypeDisplay = (
    <div className='flex'>
      <button className={`api-button ${activeApi === 'v1' ? 'active' : ''}`} onClick={() => handleApiChange(`${PONGFOX_FIX_URL_DRILL_V1}`, 'v1')}>Library</button>
      <button className={`api-button ${activeApi === 'semirandom' ? 'active' : ''}`} onClick={() => handleApiChange(`${PONGFOX_FIX_URL_DRILL_SEMIRANDOM}`, 'semirandom')}>SemiRandom Drills</button>
      <button className={`api-button ${activeApi === 'custom_drills' ? 'active' : ''}`} onClick={() => handleApiChange(`${BACKEND_API_URL_MYDRILLS_DEVICE}/${deviceId}`, 'custom_drills')}>My Custom Drills</button>
      <button className='api-button custom-drill-button' onClick={() => navigate('/createDrill', { state: { device_id: `${deviceId}`, selectedItems: selectedItems } })}>Create Custom Drill</button>
    </div>
  );

// function for handele delete custum drill
  const handleDelete = async (deviceId, drillId) => {
    if (!deviceId || !drillId) {
      toast.error("Device ID or Drill ID is missing");
      return;
    }
  
    const address = `${BACKEND_API_URL}/drills/${deviceId}/${drillId}`;
  
    try {
      const response = await fetch(address, {
        method: 'DELETE',
      });
  
      if (response.ok) {
        const result = await response.json();
        toast.success('Drill deleted successfully!');
        // Update drills list
        setDrills(prevDrills => prevDrills.filter(drill => drill.drill_id !== drillId));
        // Update selected items (uncheck checkbox)
        setSelectedItems(prevSelectedItems =>
          prevSelectedItems.filter(item => item.drill_id !== drillId)
        );
      } else {
        const errorJson = await response.json();
        const sessionName = errorJson.detail; 
  
        // Format message
        const errorMessage = (
          <>
            This Drill is in use in Session : <b>{sessionName}</b>. You can't delete it.
          </>
        );
  
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error('Error deleting drill:', error);
      toast.error('Unexpected error occurred. Please try again.');
    }
  };

  const drillListDisplay = (
    <ul className='drillList'>
      {drills.length > 0 ? (
        drills.filter(item => selectedVariation ? item.variations === selectedVariation : true)
          .map((item, index) => {
            console.log("ITEM " , item);
            // const isItemSelected = selectedItems.some(selectedItem => selectedItem.title === item.title && (selectedItem.apiType === "custom_drills" ? 1 : Number(selectedItem.id) === Number(item.id)));
            const isItemSelected = selectedItems.some(selectedItem => selectedItem.title === item.title);
            return (
              <li key={index}>
                <label className='drillLabel'>
                  <table style={{ borderCollapse: 'collapse', width: '100%', borderBottom: '1px solid #ddd', tableLayout: 'fixed' }}>
                    <tbody>
                      <tr>
                        {/* Checkbox column */}
                        <td style={{ width: '5%', borderBottom: '1px solid #ddd',  paddingRight: '20px', }}>
                          <input
                            type="checkbox"
                            value={item.drill_id} // Use drill_id for checkbox value
                            name={item.title}
                            checked={isItemSelected}
                            onChange={handleCheckboxChange}
                          />
                        </td>
                        {/* Drill information column */}
                        <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>
                          <div className="drill-info">
                            <div className="drill-title" style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                              {item.title}
                            </div>
                            <div className="drill-description" style={{ fontSize: '0.9em', color: '#666' }}>
                              {item.drillDescription}
                            </div>
                          </div>
                        </td>
                        {/* Delete icon column, shown only if apiType is "custom_drills" */}
                        {item.apiType === "custom_drills" && (
                          <td style={{ width: '5%', paddingRight: '20px', textAlign: 'right' }}>
                            <button
                              className="delete-button"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent the click from propagating to the parent div
                                handleDelete(deviceId, item.drill_id); // Pass deviceId and drillId to delete function
                              }}
                              style={{
                                padding: '5px 10px',
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                              }}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                                <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                              </svg>
                            </button>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </label>
              </li>
            );
          })
      ) : (
        <div>No Drills</div>
      )}
    </ul>
  );

  const goHome = () => {
      // Uncheck all checkboxes
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach(checkbox => checkbox.checked = false);

      // Reset input fields
      setSessionName('');
      setSessionDesc('');
      setSelectedItems([]);
      setIsUpdateMode(false); // Set edit mode to false

      // Navigate to the home page with the device ID
      navigate(`/device?device_id=${deviceId}`);
  };
  
  const block2Display = (
    <div>
      {durationDisplay}
      {sessionInputDisplay}
      <div className="section-separator"></div> {/* Separator line */}
      {variationDisplay}
      <div className="section-separator"></div> {/* Separator line */}
      {buttonTypeDisplay}
      <div className="section-separator"></div> {/* Separator line */}
      {drillListDisplay}
      <div className='buttonDiv'>
        <button className='save-btn' onClick={handleEnter}>
          {isUpdateMode ? 'UPDATE' : 'CREATE'}
        </button>
      </div>

      <button className='home-close-btn' onClick={goHome}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
          <path d="M 15 3 L 5 10 L 5 26 C 5 26.552 5.448 27 6 27 L 12 27 C 12.552 27 13 26.552 13 26 L 13 19 C 13 18.448 13.448 18 14 18 L 16 18 C 16.552 18 17 18.448 17 19 L 17 26 C 17 26.552 17.448 27 18 27 L 24 27 C 24.552 27 25 26.552 25 26 L 25 10 L 15 3 z"></path>
        </svg>
      </button>

    </div>
  );

  return (
      <div>
        <header className="App-header">
          {
            showPopup && (
              <>
                <div className="appContainer">
                  <h2>
                    Device id = <span className="highlight">{deviceId}</span>
                  </h2>
                  <div className="sessionContainer">
                    <div className="block block1">{block1Display}</div>
                    <div className="block block2">{block2Display}</div>
                  </div>
                </div>

                {showDeletePopup && sessionToDelete && (
                  <>
                  <div className="popup-backdrop" onClick={cancelDelete}></div>
                  <div className="delete-popup">
                    <h3>Delete Session</h3>
                    <p>Are you sure you want to delete this session?</p>
                    <p>Device ID: {sessionToDelete.device_id}</p>
                    <p>Session Name: {sessionToDelete.session_name}</p>
                    <div>
                      <button onClick={() => confirmDelete(sessionToDelete.device_id, sessionToDelete.session_id)}>Delete</button>
                      <button onClick={cancelDelete}>Cancel</button>
                    </div>
                  </div>
                  </>
                )}
              </>
            )
          }
        </header>
        <ToastContainer />
      </div>
  );
}

export default CreateSession;

import React, { useState, useEffect } from 'react';
import { BACKEND_API_URL } from "../constants/constants";

import {
    getDocs,
    collection,
    query,
    limit,
    startAfter,
    orderBy,
    where,
    Timestamp
} from "firebase/firestore";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { db } from "./FirebaseConfig";
import axios from 'axios';
import { useLocation } from 'react-router-dom';

function History() {
    const [drills1, setDrills1] = useState([]);
    const [drills2, setDrills2] = useState([]);
    const [drills3, setDrills3] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deviceId, setDeviceId] = useState(null);

    const location = useLocation();
    const [expandedSection, setExpandedSection] = useState(null);

    const toggleSection = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
      };

    const [selectedDate, setSelectedDate] = useState(new Date());



    function getDateRange(dateStr) {
        const startDate = new Date(dateStr);
        const endDate = new Date(dateStr);
        endDate.setDate(endDate.getDate() + 1);

        return {
            startTimestamp: startDate.getTime(),
            endTimestamp: endDate.getTime(),
        };
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const deviceIdParam = searchParams.get('device_id');
        setDeviceId(deviceIdParam);
        fetchData(deviceId);
    }, [loading, deviceId]);

    const fetchData = async (ble_id, selected_date) => {
        setLoading(true);
        try {
            console.log(ble_id, selected_date);
            selected_date = selected_date.toISOString().split('T')[0];
            const uri = `${BACKEND_API_URL}/history/${ble_id}/${selected_date}`;
            console.log(uri);
            const response = await axios.get(uri);
            console.log(response);
            setDrills1(response.data[0] || []);
            setDrills2(response.data[1] || []);
            setDrills3(response.data[2] || []);
        } catch (err) {
            console.error("Error fetching data:", err);
            setDrills1([]);
            setDrills2([]);
            setDrills3([]);
        } finally {
            setLoading(false);
        }

    };



    const fetchData2 = async (dateStr) => {
        try {
            const { startTimestamp, endTimestamp } = getDateRange(dateStr);
            let q = query(
                collection(db, "dynamic_drill"),
                where("ble", "==", "D4:36:39:A8:4F:C4"),
                where("timestamp", ">=", Timestamp.fromMillis(startTimestamp)),
                where("timestamp", "<", Timestamp.fromMillis(endTimestamp))
            );
            const querySnapshot = await getDocs(q);
            const drillData = querySnapshot.docs.map((doc) => ({
                ble: doc.data().ble,
                drill: doc.data().drill,
                humanReadableDate: doc.data().humanReadableDate,
                timestamp: doc.data().timestamp.seconds,
            }));

            console.log(drillData);
            // setDrills(drillData);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        fetchData(date);
    };

    useEffect(() => {
        fetchData(deviceId, selectedDate);
    }, [selectedDate]);


    // useEffect(() => {
    //     fetchData("2024-03-18");
    // }, [])

    const renderDrillSection = (drills, title, section) => {
        return (
            <div className="drill-section">
                <h2 onClick={() => toggleSection(section)} style={{ cursor: 'pointer' }}>
                    {title} {expandedSection === section ? '▼' : '▶'}
                </h2>
                {expandedSection === section && (
                    <div className='drill-container'>
                        {drills.length > 0 ? (
                            drills.map((drill, index) => (
                                <div className='drill' key={index}>{JSON.stringify(drill.drill)}</div>
                            ))
                        ) : (
                            <div>No Data</div>
                        )}
                    </div>
                )}
            </div>
        );
    };


    return (
        <>
            <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="yyyy-MM-dd"
            />
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    {renderDrillSection(drills1, 'Dynamic Drills', 'drills1')}
                    {renderDrillSection(drills2, 'Drill Library Drills', 'drills2')}
                    {renderDrillSection(drills3, 'Random Drills', 'drills3')}
                </>
            )}
        </>
    );
}

export default History;
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { fetchRobotConfig, postToRunRobot, submitToApi } from './api';
import OptionButtons from './OptionButtons';
import SelectedOptions from './SelectedOptions';
import DirectionSlider from './DirectionSlider';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { handlePullConfig } from './ConfigUtil';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BACKEND_API_URL, BACKEND_API_URL_CREATE_DRILL } from "../constants/constants";

const CreateDrill = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {device_id} = location.state || {};
    console.log(device_id);
    const [bluetoothAddress, setBluetoothAddress] = useState(device_id);
    const [configLoaded, setConfigLoaded] = useState(false);
    const [sliderValue, setSliderValue] = useState([0]);
    const [directionValue, setDirectionValue] = useState([[0, 100]]);
    // const [directionArray, setDirectionArray] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([[]]);
    const [easyOptions, setEasyOptions] = useState([]);
    const [mediumOptions, setMediumOptions] = useState([]);
    const [hardOptions, setHardOptions] = useState([]);
    const [directionConfig, setDirectionConfig] = useState([33, 100]);
    const [numBalls, setNumBalls] = useState('');
    const [frequencyValue, setFrequencyValue] = useState(1);
    const [submissionData, setSubmissionData] = useState(null);
    const [drillConfig, setDrillConfig] = useState([]);
    const [strokes, setStrokes] = useState({});
    const [middleValue, setMiddleValue] = useState(0);
    const [pauseValue, setPauseValue] = useState(0);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const bluetooth_address = bluetoothAddress;

    const handlePullConfigWrapper = () => {
        handlePullConfig(
            bluetooth_address,
            setConfigLoaded,
            setSliderValue,
            setSelectedOptions,
            setEasyOptions,
            setMediumOptions,
            setHardOptions,
            setDirectionConfig,
            setNumBalls,
            setFrequencyValue,
            setSubmissionData,
            setStrokes,
            setDrillConfig,
            setDirectionValue,
            setMiddleValue,
            navigate,
        );
    };

    useEffect(() => {
        getDirectionRange();
        handlePullConfigWrapper();
    }, [bluetoothAddress]);

    const getDirectionRange = async () => {
        const data = await fetchRobotConfig(bluetooth_address);
        console.log("Check", data[0].config);
        const maxMin = data[0].config;
        setDirectionValue([[maxMin.backhand || 33, maxMin.forehand || 100]]);
    }

    const handleSliderChange = (index, newValue) => {
        const updatedSliderValue = [...sliderValue];
        updatedSliderValue[index] = newValue;
        setSliderValue(updatedSliderValue);
    };

    const handleFrequencyChange = (event, newValue) => {
        setFrequencyValue(newValue);
    };

    const handleDirectionChange = (index, newValue) => {
        const updatedDirectionValue = [...directionValue];
        updatedDirectionValue[index] = newValue;
        setDirectionValue(updatedDirectionValue);
    };

    const handleOptionChange = (index, option) => {
        const currentDifficulty = sliderValue[index] === 0 ? 'Easy' : sliderValue[index] === 50 ? 'Medium' : sliderValue[index] === 100 ? 'Hard' : '';
        const newOption = { label: option, difficulty: currentDifficulty };
        const updatedSelectedOptions = [...selectedOptions];

    // Check if the option already exists
    const optionExists = updatedSelectedOptions[index]?.some(opt => opt.label === option);
    if (optionExists) {
        toast.error('This option has already been selected. Please choose a different option.');
        return;
    }

    // Allow only 1 option to be chosen
    if (updatedSelectedOptions[index]?.length < 1) {
        updatedSelectedOptions[index] = [...(updatedSelectedOptions[index] || []), newOption];
        setSelectedOptions(updatedSelectedOptions);
    } else {
        toast.error('You can only select up to 1 option.');
    }
    };

    const handleRemoveOption = (index, label) => {
        const updatedSelectedOptions = [...selectedOptions];
        updatedSelectedOptions[index] = updatedSelectedOptions[index].filter(option => option.label !== label);
        setSelectedOptions(updatedSelectedOptions);
    };

    const handleAddBall = () => {
        setSliderValue([...sliderValue, 0]);
        setDirectionValue([...directionValue, [directionConfig.backhand, directionConfig.forehand]]);
        setSelectedOptions([...selectedOptions, []]);
    };

    const handleRemoveBall = (index) => {
        const updatedSliderValue = [...sliderValue];
        updatedSliderValue.splice(index, 1);
        setSliderValue(updatedSliderValue);

        const updatedDirectionValue = [...directionValue];
        updatedDirectionValue.splice(index, 1);
        setDirectionValue(updatedDirectionValue);

        const updatedSelectedOptions = [...selectedOptions];
        updatedSelectedOptions.splice(index, 1);
        setSelectedOptions(updatedSelectedOptions);
    };

    const goToCreateSession = () => {
        navigate(`/device?device_id=${device_id}`, {state: { 
            device_id: location.state.device_id,
            selectedItems: location.state.selectedItems 
          }});
    }

    const getRPM = (frequency) => {
        if(frequency === 1.5) return 60;
        else if(frequency === 1.25) return 75;
        else if(frequency === 1.0) return 95;
        else if(frequency === 0.8) return 110;
    }

    const handlePause = (e) => {
        const pauseSelected = parseInt(e.target.value);
        setPauseValue(pauseSelected || 0);
    }

    const handleSubmit = async () => {

        // Validate title and description
        if (title.trim().length < 5) {
            toast.error('Title must be at least 5 characters long.');
            return;
        }
        
        if (description.trim().length < 5) {
            toast.error('Description must be at least 5 characters long.');
            return;
        }
        
        if (selectedOptions.some(options => options.length === 0)) {
            toast.error('Please select at least one stroke option for each ball configuration.');
            return;
        }

        const totalBalls = parseInt(numBalls, 10);
        const output = {
            title: title.trim(),
            description: description,
            isDirect: 1,
            frequency: frequencyValue,
            rpm: getRPM(frequencyValue) || 95,
            pas: 0,
            sensor: 1,
            variation: selectedOptions.length,
            random: {
                type: 1
            },
            id: bluetooth_address,
            pause: pauseValue || 0,
        };
        

        const matchedConfig = {};
        Object.keys(selectedOptions).forEach(ballIndex => {
            const index = parseInt(ballIndex, 10) + 1;
            const options = selectedOptions[ballIndex];
            options.forEach(option => {
                const selectedStroke = strokes.find(stroke => stroke.stroke === option.label);
                let currentData = selectedStroke.config;
                matchedConfig[index] = {
                    speed1: currentData.motorSpeed1,
                    speed2: currentData.motorSpeed2,
                    speed3: currentData.motorSpeed3,
                    degV: currentData.servoVertical
                };
                if (option.label === "loop") {
                    matchedConfig[index].m2_reverse = 1;
                }
            });
            output.random[index] = directionValue[ballIndex];
        });

        Object.keys(matchedConfig).forEach(key => {
            output[key] = matchedConfig[key];
        });

        delete output.config;

        const sendOutput = {
            device_id: bluetooth_address,
            title: title.trim(),
            description: description,
            isDirect: 1,
            robo_json: JSON.stringify(output)
        };

        setSubmissionData(output);
        console.log(sendOutput);
    
        try {
            // Send request to backend
            //  const response = await axios.post(`${BACKEND_API_URL_CREATE_DRILL}`, sendOutput);
            const response = await axios.post(`${BACKEND_API_URL_CREATE_DRILL}`, sendOutput);
            console.log(response);
    
            // If response is successful, proceed
            if (response.status === 201) {
                goToCreateSession();
            }
        } catch (error) {
            // Handle error from the backend
            if (error.response) {
                // Backend returned an error response
                const errorMessage = error.response.data.detail || 'Error creating drill.';
                toast.error(errorMessage);
            } else {
                // Network or other errors
                toast.error('Network error or unexpected issue occurred.');
            }
        }
    };



    const currentOptions = index => {
        if (sliderValue[index] === 0) {
            return easyOptions;
        } else if (sliderValue[index] === 50) {
            return mediumOptions;
        } else if (sliderValue[index] === 100) {
            return hardOptions;
        }
        return [];
    };

    const inputDisplay = (
      <div>
        <h5 className='headerText'>Enter Drill Details: </h5>
        <TextField
            label="Title"
            variant="outlined"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            sx={{ marginBottom: '3px', backgroundColor: 'whitesmoke' }}
        />
        <TextField
            label="Description"
            variant="outlined"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ marginBottom: '3px', backgroundColor: 'whitesmoke' }}
        />
      </div>
    );

    const frequencySlider = (
    <div>
      <h5 className='headerText'>Frequency: </h5>
      <Slider
        aria-label="Frequency"
        value={frequencyValue}
        step={null}
        valueLabelDisplay="auto"
        marks={[
            { value: 0.8, label: '0.8s' },
            { value: 1, label: '1s' },
            { value: 1.25, label: '1.25s' },
            { value: 1.5, label: '1.5s' }
        ]}
        min={0.8}
        max={1.5}
        onChange={handleFrequencyChange}
        sx={{ marginBottom: 3, width: '95%' }}
      />
    </div>
    );

    const levelDisplay = (
        sliderValue.map((_, index) => (
            <Box key={index} className='levelBoxStyle'>
              <h5 className='headerText'>Level: </h5>
              <Slider
                  aria-label="Difficulty"
                  value={sliderValue[index]}
                  step={null}
                  valueLabelDisplay="off"
                  marks={[
                      { value: 0, label: 'Easy' },
                      { value: 50, label: 'Medium' },
                      { value: 100, label: 'Hard' }
                  ]}
                  onChange={(event, newValue) => handleSliderChange(index, newValue)}
                  sx={{ marginBottom: 3, width: '95%' }}
              />
              <OptionButtons
                  options={currentOptions(index)}
                  selectedOptions={selectedOptions[index].map(option => option.label)}
                  handleOptionChange={(option) => handleOptionChange(index, option)}
                  maxSelected={1} // changed to only allow one option to be chosen instead of 3
              />
              <SelectedOptions
                  selectedOptions={selectedOptions[index]}
                  handleRemoveOption={(label) => handleRemoveOption(index, label)}
              />
              <DirectionSlider
                  value={directionValue[index]}
                  handleChange={(event, newValue) => handleDirectionChange(index, newValue)}
                  min={directionConfig.backhand || 0}
                  max={directionConfig.forehand || 100}
              />
              {sliderValue.length > 1 && (
                  <Button
                      variant="contained"
                      onClick={() => handleRemoveBall(index)}
                      sx={{ marginBottom: 3, width: '100%' }}
                  >
                      Remove Ball
                  </Button>
              )}
            </Box>
        ))
    );

    const pauseDisplay = (
      selectedOptions.length > 1 ? (
        <>
            <div className='flex'>
                <label>Pause :</label>
                <select onChange={handlePause} className='pauseSelect'>
                    <option value="0">None</option>
                    <option value="2">2 sec</option>
                    <option value="3">3 sec</option>
                    <option value="5">5 sec</option>
                </select>
            </div>
        </>
      ):(
        <></>
      )
    );

    const addBallButton = (
        <Button
          variant="contained"
          onClick={handleAddBall}
          disabled={sliderValue.length === 3}
          style={{ margin: '0 10px' }}
        >
          Add Ball
        </Button>
    );
    const submitButton = (
      <Button
        variant="contained"
        onClick={handleSubmit}
        style={{ margin: '0 10px' }}
      >
        Submit
      </Button>
    );

    const backButton = (
      <Button
        variant='contained'
        onClick={goToCreateSession}
      >
        Back
      </Button>
    );

    const submissionDataDisplay = (
      submissionData && (
        <Box sx={{ marginTop: 3, width: '100%', textAlign: 'left' }}>
          <pre>{JSON.stringify(submissionData, null, 2)}</pre>
        </Box>
      )
    );

    const block1Display = (
      <div>
        {inputDisplay}
        {frequencySlider}
        {levelDisplay}
        {pauseDisplay}
        <div className='button buttonContainer'>
          {addBallButton}
          {submitButton}
          {backButton}
        </div>
        {submissionDataDisplay}
      </div>
    );


    return (
      <div>
        <header className="App-header">
          <div className="appContainer">
            <h2>
              Create a New Drill
            </h2>
            <div className="sessionContainer">
              <div className="block block3">{block1Display}</div>
            </div>
        </div>

        </header>
        <ToastContainer />
      </div>
    );
};

export default CreateDrill;
